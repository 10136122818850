<template>
<div>

    <el-dialog title="封禁用户" :before-close="clearBan" :visible.sync="dialogVisibleMul" width="30%">

        <div>
            <el-form ref="form" label-width="80px" size="mini">
                <el-form-item label="封禁用户">
                    <UserHeader  :headerSize="'large'" v-for="item in multipleSelection" :userId="item.userId" style="margin-right:10px"></UserHeader>
                </el-form-item>
                <el-form-item style="text-align: center;" label="解禁时间">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="封禁原因">
                    <el-input v-model="banReason"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearBan">取 消</el-button>
            <el-button type="primary" @click="confirmBanMul">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog title="封禁用户" :before-close="clearBan" :visible.sync="dialogVisible" width="30%">

        <div>
            <el-form ref="form" label-width="80px" size="mini">
                <el-form-item label="封禁用户">
                    <UserHeader :userId="currentBanUserId"></UserHeader>
                </el-form-item>
                <el-form-item style="text-align: center;" label="解禁时间">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-time-picker placeholder="选择时间" v-model="time" style="width: 100%;"></el-time-picker>
                    </el-col>
                </el-form-item>
                <el-form-item label="封禁原因">
                    <el-input v-model="banReason"></el-input>
                </el-form-item>

            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="clearBan">取 消</el-button>
            <el-button type="primary" @click="confirmBan">确 定</el-button>
        </span>
    </el-dialog>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="头像" width="70">
            <template slot-scope="scope">
                <UserHeader :userId="scope.row.userId"></UserHeader>
            </template>
        </el-table-column>
        <el-table-column label="用户编号" width="100" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.userId }}</template>
        </el-table-column>
        <el-table-column label="用户名" width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.username }}</template>
        </el-table-column>
        <el-table-column label="昵称" width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.nickname }}</template>
        </el-table-column>
        <el-table-column label="创建时间" width="150" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createTime }}</template>
        </el-table-column>
        <el-table-column label="最近解封时间" width="150" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.availableTime }}</template>
        </el-table-column>
        <el-table-column label="封禁原因" width="250" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.denyReason }}</template>
        </el-table-column>

        <el-table-column label="是否在线" width="100" show-overflow-tooltip>
            <template slot-scope="scope">

                <el-button size="mini" v-if="scope.row.isLogin" type="danger" @click="kickoutUser(scope.row.userId)">强制下线</el-button>
                <el-button size="mini" v-else type="success">未在线</el-button>
            </template>

        </el-table-column>

        <el-table-column align="right" min-width="200">
            <template slot="header" slot-scope="scope">
                <el-input v-model="search" size="mini" placeholder="请输入用户编号、用户昵称、用户名、手机号、封禁原因" />
            </template>
            <!-- <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            </template> -->
        </el-table-column>
        <el-table-column align="right" width="220">
            <template slot="header" slot-scope="scope">
                <el-button size="mini" type="danger" @click="killoutMul">批量强制下线</el-button>
                <el-button size="mini" type="danger" @click="banMul">批量封禁</el-button>
            </template>
            <template slot-scope="scope">

                <el-button size="mini" type="danger" @click="ban(scope.row.userId)">封禁</el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- <div style="margin-top: 20px">
        <el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>
        <el-button @click="toggleSelection()">取消选择</el-button>
    </div> -->

    <div style="text-align: center;marginTop:40px;paddingBottom:20px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[5,10,15,20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
        </el-pagination>

    </div>

</div>
</template>

<script>
import UserHeader from '@/components/business/user/UserHeader.vue';
import dateUtils from '@/utils/dateUtils'
export default {
    name: 'UserManage',
    components: {
        UserHeader
    },
    data() {
        return {
            tableData: [],
            multipleSelection: [],
            search: '',
            currentPage: 1,
            pageSize: 10,
            dataTotal: 0,
            search: '',
            date: '',
            time: '',
            banReason: '',
            dialogVisible: false,
            dialogVisibleMul: false,
            currentBanUserId: -1
        };
    },
    watch: {
        search() {
            this.updatePage();
        },
        pageSize() {
            this.updatePage();
        },
        currentPage() {
            this.updatePage();
        }
    },
    mounted() {
        this.updatePage();

    },
    methods: {
        kickoutUser(val) {
            let that = this;

            this.$confirm('此操作将强制下线该用户, 是否继续?', '强制下线提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$apis.kickoutUser({
                    userId: val
                }).then(res => {
                    if (res.msg == '操作成功') {
                        that.$message({
                            type: 'success',
                            message: '强制下线成功'
                        })
                        that.updatePage();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消强制下线'
                });
            });

        },
        killoutMul() {
            let that = this;
            if (this.multipleSelection.length > 0) {

                this.$confirm('此操作将强制下线已选用户, 是否继续?', '强制下线提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.multipleSelection.map((item, index) => {
                        this.$apis.kickoutUser({
                            userId: item.userId
                        }).then(res => {
                            if (res.msg == '操作成功') {
                                that.$message({
                                    type: 'success',
                                    message: '强制下线成功'
                                })
                                if (index == that.multipleSelection.length - 1) {
                                    that.updatePage();
                                }
                            }
                        })
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消强制下线'
                    });
                });

            } else {
                this.$message({
                    type: 'error',
                    message: '未选择用户'
                })
            }
        },
        banMul() {
            if (this.multipleSelection.length > 0) {
                this.dialogVisibleMul = true;
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择用户'
                })
            }
        },

        clearBan() {
            this.currentBanUserId = -1;
            this.banReason = "";
            this.date = "";
            this.time = "";
            this.dialogVisible = false;
            this.dialogVisibleMul = false;
            // done();
        },
        confirmBanMul() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        for (let i = 0; i < that.multipleSelection.length; i++) {
                            that.$apis.banUser({
                                availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                                userId: that.multipleSelection[i].userId,
                                denyReason: that.banReason
                            }).then(res => {
                                if (res.msg = "操作成功") {
                                    that.$message({
                                        type: 'success',
                                        message: '禁用用户(' + that.multipleSelection[i].nickname + ')成功'
                                    })
                                    if (i == that.multipleSelection.length - 1) {
                                        that.updatePage();
                                        that.clearBan();
                                        that.multipleSelection = [];
                                        that.dialogVisibleMul = false;
                                    }
                                }
                            })
                        }

                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        confirmBan() {
            let that = this;
            if (this.date != '' && this.date != null) {
                if (this.time != '' && this.time != null) {
                    if (this.banReason != null && this.banReason.length > 5 && this.banReason.length < 255) {
                        that.$apis.banUser({
                            availableTime: dateUtils.getDate(that.date) + ' ' + dateUtils.getTime(that.time),
                            userId: that.currentBanUserId,
                            denyReason: that.banReason
                        }).then(res => {
                            if (res.msg = "操作成功") {
                                that.updatePage();
                                that.clearBan();
                                that.$message({
                                    type: 'success',
                                    message: '禁用用户成功'
                                })
                                that.dialogVisible = false;

                            }
                        })
                    } else {
                        this.$message({
                            type: 'error',
                            message: '禁用原因必须在5-255字符之间'
                        })
                    }

                } else {
                    this.$message({
                        type: 'error',
                        message: '未选择时间'
                    })
                }
            } else {
                this.$message({
                    type: 'error',
                    message: '未选择日期'
                })
            }
        },
        ban(val) {
            this.currentBanUserId = val;
            this.dialogVisible = true;
        },
        updatePage() {
            this.$apis.queryUserVos({
                username: this.search,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                orderByColumn: 'update_time',
                isAsc: 'desc'

            }).then(res => {
                // console.log(res);
                this.tableData = res.rows;
                this.dataTotal = res.total;
            })
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage = val
        }
    },
};
</script>

<style scoped>
</style>
