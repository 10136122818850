export default [
    // {
    //   name: "test", // 接口调用方法名
    //   path: '/api/test', // 后端路径
    //   method: "POST", // 默认POST
    //   headers: {"token": 123}, // 自定义单个接口的请求头
    //   desc: "登录接口", // 接口描述
    // }
    {
        name: "allDeliverOrderByBusiness",
        path: '/api/goodsManage/allDeliverOrderByBusiness',
        desc: "管理员一键发货",
    },
    {
        name: "addGoodsCategory",
        path: '/api/goodsManage/addGoodsCategory',
        desc: "新增商品类别",
    },
    {
        name: "deleteGoodsCategory",
        path: '/api/goodsManage/deleteGoodsCategory',
        desc: "删除商品类别",
    },
    {
        name: "editGoodsCategory",
        path: '/api/goodsManage/editGoodsCategory',
        desc: "编辑商品类别",
    },
    {
        name: "searchGoodsCategory",
        path: '/api/goodsManage/searchGoodsCategory',
        desc: "搜索商品类别",
    },
    {
        name: "searchGoodsCategoryByGoodsCategoryId",
        path: '/api/goodsManage/searchGoodsCategoryByGoodsCategoryId',
        desc: "搜索商品类别通过商品类别编号",
    },
    {
        name: "addGoods",
        path: '/api/goodsManage/addGoods',
        desc: "新增商品",
    },
    {
        name: "searchGoods",
        path: '/api/goodsManage/searchGoods',
        desc: "搜索全部商品",
    },
    {
        name: "searchGoodsById",
        path: '/api/goodsManage/searchGoodsById',
        desc: "搜索商品通过商品Id",
    }
    ,
    {
        name: "editGoods",
        path: '/api/goodsManage/editGoods',
        desc: "更新商品",
    },
    {
        name: "deleteGoodses",
        path: '/api/goodsManage/deleteGoodses',
        desc: "删除商品",
    },
    {
        name: "goodsesDown",
        path: '/api/goodsManage/goodsesDown',
        desc: "下架商品",
    }
    ,
    {
        name: "goodsesUp",
        path: '/api/goodsManage/goodsesUp',
        desc: "上架商品",
    },
    {
        name: "saveUserGoodsAllowRepertory",
        path: '/api/goodsManage/saveUserGoodsAllowRepertory',
        desc: "保存用户商品特定库存数",
    }
    ,
    {
        name: "saveUserGoodsSpecialPrice",
        path: '/api/goodsManage/saveUserGoodsSpecialPrice',
        desc: "保存用户商品特定价格",
    }
    ,
    {
        name: "queryAllOrderByBusiness",
        path: '/api/goodsManage/queryAllOrderByBusiness',
        desc: "业务人员查询全部订单",
    }
    ,
    {
        name: "deliverOrderByBusiness",
        path: '/api/goodsManage/deliverOrderByBusiness',
        desc: "业务人员发货",
    }
    ,
    {
        name: "deliverSuccessOrderByBusiness",
        path: '/api/goodsManage/deliverSuccessOrderByBusiness',
        desc: "业务人员已送货",
    },
    {
        name: "getMoneyOrderByBusiness",
        path: '/api/goodsManage/getMoneyOrderByBusiness',
        desc: "业务人员结款",
    }
    ,
    {
        name: "undeliverOrderByBusiness",
        path: '/api/goodsManage/undeliverOrderByBusiness',
        desc: "业务人员取消发货",
    }
    ,
    {
        name: "ungetMoneyOrderByBusiness",
        path: '/api/goodsManage/ungetMoneyOrderByBusiness',
        desc: "业务人员取消结款",
    }
    ,
    {
        name: "undeliverSuccessOrderByBusiness",
        path: '/api/goodsManage/undeliverSuccessOrderByBusiness',
        desc: "业务人员取消送达",
    }
    ,
    {
        name: "businessCancelOrder",
        path: '/api/goodsManage/businessCancelOrder',
        desc: "管理员取消订单",
    }
]