<template>
<div>
    <div style="width: 300px;position:fixed;top:20px;left:50%;z-index:1000;">
        <el-input class="queryInput" v-model="query.keyword" placeholder="请输入订单相关信息"></el-input>
    </div>
    <el-empty v-if="query.rows.length <= 0" :image-size="300"></el-empty>
    <div v-for="item in query.rows" :key="item.orderId" style="margin-bottom: 30px;">
        <el-descriptions class="margin-top" :title="'订单号：'+item.orderId" :column="3" size="mini" border>
            <!-- <template slot="extra">
                <el-button type="primary" size="small">发货</el-button>
            </template> -->
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i>
                    收货人
                </template>
                {{ item.consignee }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-mobile-phone"></i>
                    手机号
                </template>
                {{ item.phone }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-thumb"></i>
                    订单操作
                </template>
                <el-button type="primary" size="small" @click="deliverSuccessOrderByBusiness(item.orderId)">确认送达</el-button>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-location-outline"></i>
                    所在地区
                </template>
                {{ item.area }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-place"></i>
                    详细地址
                </template>
                {{ item.detailArea }}
                <!-- <el-tag size="small">学校</el-tag> -->
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-copy-document"></i>
                    地址操作
                </template>
                <el-button type="primary" size="small" @click="$tools.copyContent(item.area+' '+item.detailArea)">一键复制地址</el-button>

            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-coin"></i>
                    订单原始总价
                </template>
                ￥{{item.orderRawCountPrice}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-coin"></i>
                    订单折后总价
                </template>
                ￥{{item.orderNowCountPrice}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-coin"></i>
                    订单已实付金额
                </template>
                ￥{{item.orderActuallyPaid}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-c-scale-to-original"></i>
                    订单状态
                </template>
                {{item.orderSatus}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-time"></i>
                    订单创建时间
                </template>
                {{item.createTime}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i>
                    订单创建者
                </template>
                <UserHeader :user-id="item.createBy"></UserHeader>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-time"></i>
                    订单更新时间
                </template>
                {{item.updateTime}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user-solid"></i>
                    订单更新者
                </template>
                <UserHeader :user-id="item.updateBy"></UserHeader>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-connection"></i>
                    订单回退操作
                </template>
                <el-button type="danger" size="small" @click="undeliverOrderByBusiness(item.orderId)">取消发货</el-button>
            </el-descriptions-item>
        </el-descriptions>
        <el-descriptions v-for="(item2,index) in item.orderGoodsSnapshootVos" :key="item2.orderGoodsSnapshootId" style="margin-top: 10px;" class="margin-top" :title="'商品：'+(index+1)" :column="4" size="mini" border>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-help"></i>
                    商品图片
                </template>
                <QuickImage :url="item2.goodsImage"></QuickImage>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-s-flag"></i>
                    商品名称
                </template>
                {{ item2.goodsName }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-coin"></i>
                    商品原价
                </template>
                ￥{{item2.rawPrice}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-coin"></i>
                    商品折后价
                </template>
                ￥{{item2.actualPrice}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-soccer"></i>
                    商品数量
                </template>

                <el-tag style="font-size: 1.2em;font-weight:600">{{item2.goodsCount}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-notebook-2"></i>
                    商品描述
                </template>
                {{item2.goodsDescribe}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-collection"></i>
                    商品单位
                </template>
                {{item2.goodsUnit}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-receiving"></i>
                    商品规格
                </template>
                {{item2.goodsSpecification}}
            </el-descriptions-item>
        </el-descriptions>
    </div>

    <div style="text-align: center;marginTop:40px;paddingBottom:20px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[1,5,10,20]" :page-size="pageConfiguration.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageConfiguration.dataTotal">
        </el-pagination>

    </div>
</div>
</template>

<script>
import QuickImage from '@/components/image/QuickImage'
import UserHeader from '@/components/business/user/UserHeader'
export default {
    name: 'WaitDeliverOrderManage',
    components: {
        QuickImage,
        UserHeader
    },
    watch: {
        'pageConfiguration.pageNum'() {
            this.updatePage();
        },
        'pageConfiguration.pageSize'() {
            this.updatePage();
        },
        'query.keyword'() {
            this.updatePage();
        }
    },
    data() {
        return {
            query: {
                keyword: '',
                rows: []
            },
            pageConfiguration: {
                pageSize: 1,
                dataTotal: 0,
                pageNum: 1
            }
        };
    },
    mounted() {
        this.updatePage();
        this.updateToalCount();
    },
    methods: {

        undeliverOrderByBusiness(id) {
            let that = this;

            this.$confirm('此操作将取消发货该订单, 是否继续?', '取消发货提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                that.$apis.undeliverOrderByBusiness({
                    id
                }).then((res) => {
                    if (res.code == 200) {

                        that.updatePage();

                        that.$message({
                            type: 'success',
                            message: '取消发货成功'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已关闭取消发货'
                });
            });

        },
        deliverSuccessOrderByBusiness(id) {
            let that = this;

            this.$confirm('此操作将确认送达该订单, 是否继续?', '确认送达提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                that.$apis.deliverSuccessOrderByBusiness({
                    id
                }).then((res) => {
                    if (res.code == 200) {

                        that.updatePage();

                        that.$message({
                            type: 'success',
                            message: '确认送达成功'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消确认送达'
                });
            });

        },
        updateToalCount() {
            let that = this;
            that.$apis.queryAllOrderByBusiness({
                isAsc: 'asc',
                orderByColumn: 'create_time',
                keyword: '',
                orderSatus: '派送中',
                pageNum: that.pageConfiguration.pageNum,
                pageSize: that.pageConfiguration.pageSize
            }).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    // that.$message({
                    //     showClose: true,
                    //     message: '还有' + res.total + '个订单待发货',
                    //     type: 'success'
                    // });
                    if (res.total > 0) {
                        that.$notify({
                            duration: 5000,
                            title: '派送订单提示',
                            message: '还有' + res.total + '个订单正在派送',
                            type: 'success'
                        });
                    }
                    // that.pageConfiguration.dataTotal = res.total;
                }
            })
        },
        updatePage() {
            let that = this;
            that.$apis.queryAllOrderByBusiness({
                isAsc: 'asc',
                orderByColumn: 'create_time',
                keyword: that.query.keyword,
                orderSatus: '派送中',
                pageNum: that.pageConfiguration.pageNum,
                pageSize: that.pageConfiguration.pageSize
            }).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    that.query.rows = res.rows;
                    that.pageConfiguration.dataTotal = res.total;
                }
            })

        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageConfiguration.pageSize = val;
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageConfiguration.pageNum = val
        }
    },
};
</script>

<style scoped>
.queryInput {
    opacity: 0.7;
}

.queryInput:hover,
.queryInput:focus {
    opacity: 1;
}
</style>
